import { StaticImage } from "gatsby-plugin-image";
import { motion } from "framer-motion";
import { variantPages } from "../animations/pageTransition";
import React from "react";
import MapRoute from "../components/mapRoute";
import Feedback from "../components/service/feedback";
import MetaTag from "../components/metaTag";
import Footer from "../components/footer";
import { Directions } from "../components/svg/services";
import {
  IoCheckmarkDoneOutline,
  IoTimeOutline,
  IoDocumentTextOutline,
} from "react-icons/io5";
import { minicarcarrierSEO } from "../data/seo";
import * as styles from "../styles/pages/services.module.scss";

function Minicarcarrier() {
  return (
    <motion.main
      variants={variantPages}
      initial="initial"
      exit="exit"
      animate="animate"
    >
      <MetaTag data={minicarcarrierSEO} />
      <section className={styles.container}>
        <div className={styles.title}>
          <h1>Мини автовоз</h1>
        </div>

        <div className={styles.description}>
          <div className={styles.header}>
            <h3>Мини эвакуатор от 1 до 4 автомобилей</h3>
            <p className={styles.duration}>от 2 до 10 рабочих дней</p>
          </div>
          <div className={styles.content}>
            <div className={styles.preview}>
              <StaticImage
                src="../images/services/4.jpeg"
                alt="Перевозка автомобиля мини автовозом"
              />
            </div>
            <div className={styles.info}>
              <p>
                Малые автовозы - эвакуаторы могут вмещать от одной до четырёх
                машин в зависимости от модели. Если вам нужна невысокая
                стоимость доставки, скорость и манёвренность, то мини автовоз
                подойдёт вам идеально. Мини автовоз доставит автомобили самого
                разного типа: легковые, внедорожники, минивэны, мотоциклы.
                Безопасность и надёжность перевозок обеспечивают простые и
                прозрачные условия нашей работы.
              </p>
            </div>
          </div>
        </div>
        <MapRoute />

        <div className={styles.about}>
          <div className={styles.block}>
            <h3>Преимущества</h3>
            <p className={styles.text}>
              Мини автовозы компактны, что важно, например, в тесных условиях
              при разгрузке в дилерских центрах. В числе преимуществ:
            </p>
            <ul className={styles.list}>
              <li>
                Быстрая погрузка и выгрузка. Конструкция автовоза специально
                предусмотрена для заезда легковых автомобилей
              </li>
              <li>
                Скорость доставки. В среднем автовоз за сутки проходит более 500
                км
              </li>
              <li>
                Совершенный и практичный способ крепления автомобилей на
                платформах при помощи специальных ремней и откатников
              </li>
              <li>
                Профессиональные кадры. Водитель автовоза – это не просто
                дальнобойщик, а специалист узкого профиля, прошедший специальное
                обучение для работы на автовозе
              </li>
              <li>Доставка автомобиля «до двери»</li>
            </ul>
            <Directions className={styles.background} />
            <div className={styles.hint}>
              <IoCheckmarkDoneOutline />
            </div>
          </div>

          <div className={styles.block}>
            <h3>Формирование сроков и цены</h3>
            <p className={styles.text}>
              <span>Как складывается цена:</span>
              <br />
              <br />
            </p>
            <ul className={styles.list}>
              <li>
                Габариты автомобиля. Все а/м мы распределили на несколько
                классов. Градация связана с возможностями автовоза
              </li>
              <li>Маршрут перевозки</li>
              <li>
                Доп. услуги доставки: доставка от «двери до двери»;
                дополнительное страхование груза; погрузка / выгрузка аварийных
                или страховых автомобилей
              </li>
            </ul>
            <p className={styles.text}>
              <br />
              <span>
                Сроки:
                <br />В среднем перевозка занимает от 3 до 10 рабочих дней в
                зависимости от направлений и сезонного фактора
              </span>
              <br />
              <br />
              Дополнительное время требуется на приемку и оформление
              сопроводительных документов и обработку груза по факту завершения
              перевозки
            </p>
            <div className={styles.hint}>
              <IoTimeOutline />
            </div>
          </div>

          <div className={styles.block}>
            <h3>Документы для перевозки</h3>
            <ul className={styles.list}>
              <li>Копии паспорта отправителя или заказчика</li>
              <li>
                Реквизитная карточка юридического лица и доверенность на
                представителя или печать организации
              </li>
              <li>
                Копия документа на автомобиль (ПТС или свидетельство о
                регистрации)
              </li>
              <li>
                Контактные данные отправителя и получателя (ФИО и телефоны для
                связи)
              </li>
              <li>Маршрут доставки</li>
              <li>Примерная рыночная стоимость автомобиля</li>
              <li>
                Адрес погрузки и выгрузки, в случае если автомобиль доставляется
                с адреса в адрес
              </li>
            </ul>
            <div className={styles.hint}>
              <IoDocumentTextOutline />
            </div>
          </div>

          <div className={styles.block}>
            <p className={styles.text}>
              Многие годы компания «Автовоз Логистик» предоставляет услуги по
              перевозке габаритных грузов, в том числе автомобилей и
              мототехники.
              <br />
              <br />
              Используя современные решения, мы с большой ответственностью
              подходим к их транспортировке. Междугородняя перевозка с помощью
              мини автовоза удобнее, экономичнее и быстрее, чем другими видами
              транспорта.
              <br />
              <br />
              Компания «Автовоз Логистик» всегда идёт навстречу заказчику. Для
              всех клиентов мы становимся надёжными партнёрами, расширяя для
              каждого географию услуг.
            </p>
            <Directions className={styles.background} />
          </div>
        </div>

        <Feedback />
      </section>
      <Footer />
    </motion.main>
  );
}

export default Minicarcarrier;
