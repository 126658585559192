import React from "react";

export function Directions(props) {
  return (
    <svg
      {...props}
      viewBox="0 0 391 151"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M77.193 17.447C50.753-.108 34.353-1.439 7.364 13.22 2.741 15.729 0 20.664 0 25.925V136c0 8.284 6.716 15 15 15h360c8.284 0 15-6.716 15-15V25.205c0-4.866-2.221-9.733-6.67-11.704-6.757-2.994-11.753-1.432-19.061 3.946l-26.235 19.508c-18.017 13.6-28.701 13.41-48.94 0L251.255 8.819c-19.392-11.91-29.69-11.606-46.921 0l-32.795 24.384c-26.93 20.295-42.818 21.877-73.156 0l-21.19-15.756Z"
        fill="#02124a"
      />
      <path
        d="M1 22.083c31.471-18.598 48.309-18.697 76.995 0L99.13 37.51c30.26 21.42 46.108 19.872 72.969 0l32.71-23.875c17.187-11.363 27.458-11.662 46.801 0l37.742 27.549c20.187 13.13 30.844 13.317 48.814 0l26.168-19.101c9.477-6.847 15.055-7.376 25.665 0"
        stroke="#02124a"
        strokeWidth={2}
      />
    </svg>
  );
}

export function Duration(props) {
  return (
    <svg
      {...props}
      viewBox="0 0 69 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.466.75C15.836.75.75 15.87.75 34.5c0 18.63 15.086 33.75 33.716 33.75 18.664 0 33.784-15.12 33.784-33.75C68.25 15.87 53.13.75 34.466.75ZM34.5 61.5c-14.918 0-27-12.083-27-27 0-14.918 12.082-27 27-27 14.917 0 27 12.082 27 27 0 14.917-12.083 27-27 27Zm1.688-43.875h-5.063v20.25l17.719 10.631 2.531-4.151-15.188-9.011V17.625Z"
        fill="#4393F5"
      />
    </svg>
  );
}

export function Document(props) {
  return (
    <svg
      {...props}
      viewBox="0 0 50 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 59.849V0h33.471c.194.094.368.222.513.378.855.883 1.73 1.758 2.598 2.636l3.94 3.977 4.18 4.204a8155.93 8155.93 0 0 0 4.995 5.045c.129.117.231.26.299.42v43.344H.453A.635.635 0 0 1 0 59.85Zm7.264-29.925v22.614c.022.189.086.252.282.265h34.916c.218 0 .248-.042.257-.265V19.225a.182.182 0 0 0-.05-.152.187.187 0 0 0-.151-.058H30.126c-.244 0-.274-.046-.282-.294V7.534c0-.282-.09-.358-.376-.37h-.21c-.474 0-.948.025-1.423.025-.474 0-.829-.021-1.243-.021H7.555c-.227.02-.27.063-.278.282v.281l-.013 22.193Z"
        fill="#4393F5"
      />
      <path
        d="M24.943 37.622H12.602c-.397 0-.483-.093-.483-.488v-2.013c0-.286.026-.312.33-.337h24.433c.142-.008.285-.008.427 0 .244.026.304.097.32.345a.456.456 0 0 1 0 .076v2.102c0 .29-.03.315-.324.323H24.951l-.008-.008ZM24.792 29.735h-12.46a.182.182 0 0 1-.186-.113.173.173 0 0 1-.01-.076V27.12a.18.18 0 0 1 .057-.138.19.19 0 0 1 .143-.05h24.686c.551 0 .607.054.603.596v1.682c.006.092.006.185 0 .277a.271.271 0 0 1-.079.186.28.28 0 0 1-.186.083 1.742 1.742 0 0 1-.257 0H24.801l-.009-.02ZM24.873 42.663h12.393c.286 0 .358.092.371.378a.809.809 0 0 1 0 .1v1.99a.456.456 0 0 1 0 .075c0 .231-.038.265-.269.282H12.481a.321.321 0 0 1-.239-.085.309.309 0 0 1-.098-.23 1.299 1.299 0 0 1 0-.181v-2.14a.162.162 0 0 1 .099-.154.171.171 0 0 1 .063-.014H24.874v-.021Z"
        fill="#4393F5"
      />
    </svg>
  );
}
