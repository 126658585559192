// extracted by mini-css-extract-plugin
export var about = "services-module--about--8Zjvh";
export var additional = "services-module--additional--zy6WT";
export var background = "services-module--background--rh7fC";
export var block = "services-module--block--HyDve";
export var blocktworow = "services-module--blocktworow--UuoUo";
export var container = "services-module--container--t5pGS";
export var content = "services-module--content--6vNQ1";
export var description = "services-module--description--QQR67";
export var directionform = "services-module--directionform--umcA3";
export var directionsinfo = "services-module--directionsinfo--ANqfg";
export var duration = "services-module--duration--ZeTUC";
export var header = "services-module--header--1ONYq";
export var hint = "services-module--hint--Q3m0F";
export var info = "services-module--info--zE3Nc";
export var left = "services-module--left--O0Coz";
export var leftshort = "services-module--leftshort--P-DRa";
export var link = "services-module--link--1-0i-";
export var linksdirections = "services-module--linksdirections--fOOeJ";
export var list = "services-module--list--kfSB2";
export var phone = "services-module--phone--I7AVo";
export var preview = "services-module--preview--ejnm0";
export var right = "services-module--right--UyKQm";
export var subtitle = "services-module--subtitle--S+k2W";
export var text = "services-module--text--T-Yvu";
export var title = "services-module--title---gzCH";